import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import mapDispatchToProps from "../redux/mapDispatchToProps";
import mapStateToProps from "../redux/mapStateToProps";

import LoadingButton from "@mui/lab/LoadingButton";
import InputIcon from "@mui/icons-material/Input";
import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
// import logoKardham from "../assets/images/logoKardham.svg";
import logoNewKardham from "../assets/images/logoKardhamNew.png";

import "./auth-layout.scss";

const AuthLayout = (props) => {
  const {
    loginUser,
    forgotPassword,
    resetPassword,
    code,
    emailConfirmation
  } = props;
  const prevErrMess = localStorage.getItem("client:error");
  const [logState, setLogState] = useState({});
  const [isLoading, setLoading] = React.useState(false);
  const [isForgot, setIsForgot] = React.useState(false);
  const [isNotValid, setIsNotValid] = React.useState(true);
  const [infoMessage, setInfoMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [isLogin, setIsLogin] = React.useState(true);
  const [resetState, setResetState] = useState({});
  const [isResetNotValid, setResetIsNotValid] = React.useState(true);
  const [accountActivated, setAccountActivated] = React.useState(false);
  const [accountAlreadyActivated, setAccountAlreadyActivated] = React.useState(false);
  //const [confirmedLogin, setConfirmedLogin] = React.useState("");
  //console.log(isLogin)

  useEffect(() => {
    const location = window.location;
    const urlParams = new URLSearchParams(location.search);
    //console.log(urlParams.get("confirmation"))
    if (urlParams.get("code")) {
      //console.log("eeesx")
      setIsLogin(false);
    }
    if (prevErrMess && prevErrMess!==''){
        setErrorMessage(prevErrMess);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (code !== ""){
      //console.log(code)
      emailConfirmation({confirmation : code}).then(res => {
        //console.log('emailConfirmation', res)
        if (res?.data.status === "OK") {
          //setConfirmedLogin(res?.data.login);
          setAccountActivated(true);
        }
        else {
          if (res?.data.status === "KO"){
            setAccountAlreadyActivated(true)
          }
        }
        setIsLogin(true)
      }).catch(err => {
        setErrorMessage("une erreur est survenue lors de l'envoie du token vers le serveur!")
        console.log('error', err)
      })
    }
  }, [code])

  useEffect(() => {
    if (!isForgot) setIsNotValid(true);
  }, [isForgot]);

  const login = (event) => {
    setLoading(true);
    loginUser(logState)
      .then((user) => {
        console.log(user)
        setErrorMessage("");
        localStorage.setItem("clientmmgmt", user.data.jwt);
        localStorage.removeItem('client:error');

        const location = window.location;
        window.location.replace(location.origin);
        //window.location.reload();
      })
      .catch((error) => {
        setErrorMessage("Login et/ou mot de passe incorrect");
        setLoading(false);
      });
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      login();
    }
  };

  const forgotpassword = (e) => {
    setLoading(true);
    forgotPassword({ email: logState.identifier }).then((r) => {
      setInfoMessage(
        `Un mail contenant un lien de réinitialisation vous a été envoyé à l'adresse ${logState.identifier}`
      );
      setLoading(false);
    });
  };

  const handleClick = (e) => {
    if (isForgot) {
      forgotpassword(e);
    } else {
      login(e);
    }
  };

  const checkValidity = () => {
    let valid = true;

    if (isForgot) {
      const email = logState.identifier;
      valid = email !== ""; //TODO check email validity
    } else {
      const email = logState.identifier;
      const pwd = logState.password;

      valid = email !== "" && pwd !== "";
    }

    setIsNotValid(!valid);
  };

  const checkResetValidity = () => {
    let valid = true;

    const newpass = resetState.newPassword;
    const confirmpass = resetState.confirmNewPassword;

    valid = newpass !== "" && confirmpass !== "" && newpass === confirmpass;

    setResetIsNotValid(!valid);
  };

  const handleClickReset = (e) => {
    const location = window.location;
    const urlParams = new URLSearchParams(location.search);
    setLoading(true);
    resetPassword({
      password: resetState.newPassword,
      passwordConfirmation: resetState.confirmNewPassword,
      code: urlParams.get("code"),
    })
      .then((r) => {
        setInfoMessage(`Votre mot de passe a bien été réinitialisé.`);
        setLoading(false);
      })
      .catch((error) => {
        setInfoMessage(
          "Le code de confirmation est incorrect, veuillez réinitialiser à nouveau votre mot de passe"
        );
        setLoading(false);
        console.log("error", error.response);
      });
  };

  const gotoLogin = () => {
    window.location.href = window.location.origin;
  };

  return (
    <div className="auth">
      <div className="login">
        <div className="logoContaint">
          <img src={logoNewKardham} alt="Logo kardham" />
        </div>
        <div className="formContaint">
          {accountActivated &&
            <p className="textforConfirmation">
              Votre compte a bien été activé.
            </p>
          }
          {accountAlreadyActivated &&
            <p className="textforConfirmation">
              Token invalide ou compte déjà activé.
            </p>
          }
          <p className="textforLogin">
            Connectez-vous pour pouvoir créer vos propres tickets{" "}
          </p>
          {isLogin && (
            <form onKeyDown={handleKeyDown} noValidate>
              <input
                autoFocus
                className="sign-up sign-in reset"
                type="email"
                placeholder="Adresse email"
                autoComplete="off"
                onChange={(event) => {
                  const identifier = event.target.value;
                  logState.identifier = identifier;
                  checkValidity();
                }}
              />
              {!isForgot && (
                <input
                  className="sign-up sign-in"
                  type="password"
                  placeholder="Mot de passe"
                  autoComplete="off"
                  onChange={(event) => {
                    const password = event.target.value;
                    logState.password = password;
                    checkValidity();
                  }}
                />
              )}
              <LoadingButton
                loading={isLoading}
                disabled={isNotValid}
                loadingPosition="start"
                className={isForgot ? "save" : "save"}
                onClick={() => handleClick()}
              >
                {isForgot
                  ? "Envoyer le lien de réinitialisation"
                  : "Se connecter"}
              </LoadingButton>

              {errorMessage && (
                <Alert severity="warning">{errorMessage}</Alert>
              )}
              {infoMessage && <Alert severity="info">{infoMessage}</Alert>}
              <div className="forgot">
                <Button
                  variant="text"
                  onClick={() => {
                    setIsForgot(!isForgot);
                    setErrorMessage("");
                    setInfoMessage("");
                  }}
                >
                  {isForgot ? "Se connecter" : "Mot de passe oublié ?"}
                </Button>
              </div>
            </form>
          )}
          {!isLogin && (
            <form noValidate>
              <input
                className="sign-up sign-in reset"
                type="password"
                placeholder="Nouveau mot de passe"
                autoComplete="off"
                onChange={(event) => {
                  resetState.newPassword = event.target.value;
                  checkResetValidity();
                }}
              />
              <input
                className="sign-up sign-in reset"
                type="password"
                placeholder="Confirmation mot de passe"
                autoComplete="off"
                onChange={(event) => {
                  resetState.confirmNewPassword = event.target.value;
                  checkResetValidity();
                }}
              />
              <LoadingButton
                loading={isLoading}
                disabled={isResetNotValid}
                loadingPosition="start"
                className="reset"
                startIcon={<InputIcon />}
                onClick={() => handleClickReset()}
              >
                Réinitialiser le mot de passe
              </LoadingButton>
              {infoMessage && (
                <>
                  <Alert severity="info">{infoMessage}</Alert>
                  <div className="forgot">
                    <Button
                      variant="text"
                      onClick={() => {
                        gotoLogin();
                      }}
                    >
                      Se connecter
                    </Button>
                  </div>
                </>
              )}
            </form>
          )}
        </div>
      </div>
    </div>
  );
};
export default connect(mapStateToProps, mapDispatchToProps)(AuthLayout);
